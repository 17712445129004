import { Controller } from "@hotwired/stimulus";
import { dynamicDialog } from "../components/ir_dialog/helper";

export default class extends Controller {
  static values = {
    powerUser: Boolean,
  };

  powerUserDialogWidth = 850;
  regularDialogWidth = 450;

  click(event) {
    const { elementIdentifier, elementType, contentData } = event.params;

    const header = this.#dialogHeader(elementType, elementIdentifier);
    const content = this.#dialogContents(contentData);
    const width = this.#dialogWidth(elementType);

    dynamicDialog({ header, content, width });

    this.#highlight(event.currentTarget);
  }

  #dialogHeader(elementType, identifier) {
    return elementType === "module" ? `Module: ${identifier}` : `Chassis: ${identifier}`;
  }

  #dialogContents(contentData) {
    const contentDataRow = (key, value) => {
      return `
        <tr>
          <th>${key}</th>
          <td>${value}</td>
        </tr>
      `;
    };

    const contentDataRows = Object.keys(contentData)
      .map((key) => {
        return contentDataRow(key, contentData[key]);
      })
      .join("\n");

    const content = `
      <div class="bx-result__details">
        <table class="bx-result__dialog__table">
          <tbody>
            ${contentDataRows}
          </tbody>
        </table>
      </div>
    `;

    return content;
  }

  #dialogWidth(elementType) {
    console.log({ elementType });
    if (elementType === "chassis") return this.regularDialogWidth;

    return this.powerUserValue ? this.powerUserDialogWidth : this.regularDialogWidth;
  }

  #highlight(div) {
    const selectedClassName = "bx-result--selected-item";

    Array.from(document.querySelectorAll(`.${selectedClassName}`)).forEach((el) => {
      el.classList.remove(selectedClassName);
    });

    const detailsDiv = div.querySelector(".bx-result__details");
    if (!detailsDiv || detailsDiv.innerHTML === "") return;

    div.classList.add(selectedClassName);
  }
}
